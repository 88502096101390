<template>
  <span>
    <b-row v-if="cards && cards.length" class="match-height">
      <b-col v-for="(card, index) in cards" :key="index" md="4" lg="3" class="mb-2">
        <b-card :title="getCardTitle(card)" :sub-title="card.engagement" class="text-center" border-variant="dark">
          <template #header>
            <span>
              {{ timeAgo(card.createdAt) }}
            </span>
            <b-badge :variant="getBadgeVariant(card.status)" :class="getBadgeClass(card.status)">
              {{ card.status }}
            </b-badge>
          </template>
          <b-card-text>
            {{ getBodyText(card) }}
          </b-card-text>
          <template v-if="card.status != 'Pending'" #footer>
            <div class="d-flex justify-content-around">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success"
                :to="{ name: 'survey-view', params: { id: card._id } }">
                View Evaluation
              </b-button>
              <b-button v-if="card.status === 'In review'" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                @click="confirmSubmit(card)">
                Submit
              </b-button>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-card v-else class="position-static p-1">
      <blockquote class="blockquote mb-0">
        <p>None of your advisees have any surveys to fill at this moment.</p>
        <footer class="blockquote-footer">
          <span class="text-secondary h5">
            Please check back later.
            <!-- <cite title="Source Title">Source Title</cite> -->
          </span>
        </footer>
      </blockquote>
    </b-card>
  </span>

</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BButton, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive';
import { timeAgo } from '../../utils';

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    cards: {
      required: true
    },
  },
  methods: {
    getCardTitle(card) {  
      if (card.type === 'selfEval') {
        return `Self Eval Review for ${card.reviewee.fullName}`;
      }
      const category = card.reviewee.level.category;
      const prefix = this.typeNames[category] ? this.typeNames[category][card.type] : card.type;
      return `${prefix} Review for ${card.reviewee.fullName}`;
    },
    getBodyText(card) {
      return `Performance review feedback from ${card.reviewer.fullName}.`
    },
    getBadgeVariant(status) {
      if (status === 'Submitted') {
        return 'success';
      } else if (status === 'In review') {
        return 'info';
      } else {
        return 'warning';
      }
    },
    getBadgeClass(status) {
      if (status === 'Submitted') {
        return 'badge-glow';
      } else {
        return 'badge';
      }
    },
    confirmSubmit(card) {
      this.$swal({
        title: 'Are you sure?',
        text: "I have reviewed this form in light of the external feedback and discussed it with the individual",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        cancelButtonText: 'No, cancel!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.submitSurvey(card);
        }
      });
    },
    submitSurvey(card) {
      this.$http.put(`/surveys/${card._id}/submit`)
        .then(() => {
          this.$swal({
            title: 'Survey submitted successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
          card.status = 'Submitted';
        })
        .catch((err) => {
          console.error('Error submitting survey', err);
          this.$swal({
            title: 'Error!',
            text: 'Error submitting survey',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },
    timeAgo,
  },
  beforeCreate() {
    this.$http.get('/questionnaires/types')
      .then(response => {
        this.typeNames = response.data;
      })
      .catch(error => console.log(error))
  },
  data() {
    return {
      typeNames: {}
    }
  }
}
</script>