// utils.js

export function timeAgo(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    const timeDifference = currentDate - givenDate;

    // Calculate differences
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
    let monthsDifference = currentDate.getMonth() - givenDate.getMonth();
    let daysInMonthDifference = currentDate.getDate() - givenDate.getDate();

    if (daysInMonthDifference < 0) {
        monthsDifference -= 1;
        daysInMonthDifference += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    }

    if (monthsDifference < 0) {
        yearsDifference -= 1;
        monthsDifference += 12;
    }

    if (yearsDifference > 0) {
        return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
    } else if (monthsDifference > 0) {
        return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
    } else if (daysDifference > 0) {
        return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else if (hoursDifference > 0) {
        return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
        return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
    } else {
        return 'Just now';
    }
}

export function getSurveyTypeFromTitle(title, types) {
    if (title === types.type1) {
        return "type1"
    } else if (title === types.type2) {
        return "type2"
    } else {
        return "selfEval"
    }
}

// export function timeInRole(dateString) {
//     const givenDate = new Date(dateString);
//     const currentDate = new Date();

//     const timeDifference = currentDate - givenDate;

//     // Calculate differences
//     const minutesDifference = Math.floor(timeDifference / (1000 * 60));
//     const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
//     const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
//     let yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
//     let monthsDifference = currentDate.getMonth() - givenDate.getMonth();
//     let daysInMonthDifference = currentDate.getDate() - givenDate.getDate();

//     if (daysInMonthDifference < 0) {
//         monthsDifference -= 1;
//         daysInMonthDifference += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
//     }

//     if (monthsDifference < 0) {
//         yearsDifference -= 1;
//         monthsDifference += 12;
//     }

//     if (yearsDifference > 0) {
//         return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''}`;
//     } else if (monthsDifference > 0) {
//         return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''}`;
//     } else if (daysDifference > 0) {
//         return `${daysDifference} day${daysDifference > 1 ? 's' : ''}`;
//     } else if (hoursDifference > 0) {
//         return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''}`;
//     } else if (minutesDifference > 0) {
//         return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''}`;
//     } else {
//         return '0 seconds';
//     }
// }
export function timeInRole(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    let yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();
    let monthsDifference = currentDate.getMonth() - givenDate.getMonth();
    let daysDifference = currentDate.getDate() - givenDate.getDate();

    if (daysDifference < 0) {
        monthsDifference -= 1;
        daysDifference += new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    }

    if (monthsDifference < 0) {
        yearsDifference -= 1;
        monthsDifference += 12;
    }

    const parts = [];
    if (yearsDifference > 0) {
        parts.push(`${yearsDifference}y`);
    }
    if (monthsDifference > 0) {
        parts.push(`${monthsDifference}m`);
    }
    if (daysDifference > 0) {
        parts.push(`${daysDifference}d`);
    }

    return parts.length > 0 ? parts.join(' ') : '0d';
}