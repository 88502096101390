<template>
  <span>
    <span v-if="!loading">
      <div v-if="isCycleActive">
        <!-- If user department is external -->
        <b-tabs v-if="userData.level.department.toLowerCase() === 'external'">
          <!-- Client Surveys TAB -->
          <b-tab title="Requested Surveys">
            <app-collapse>
              <client-cards :cards="surveys.incoming" />
            </app-collapse>
          </b-tab>
        </b-tabs>

        <!-- If user level is anything else -->
        <b-tabs v-else>
          <!-- Self Evaluation -->
          <b-tab title="Self Evaluation">
            <self-eval-card class="mt-2" surveyType="selfEval" :card="surveys.selfEval" />
          </b-tab>

          <!-- My Outgoing Requests TAB  -->
          <b-tab active title="My Requests">
            <app-collapse>
              <survey-collapse-item-refresh 
                :title="typeNames.type1" 
                :notifCount="getNotifCount(surveys.outgoing.type1)"
                :nominateButton="true"
                :nominateButtonDisabled="!canNominateState.type1"
                :nominationLimitReached="nominationLimitReached.type1"
                :typeNames="typeNames"
                @nominate="handleNominate"
                @refresh="refreshSurveys('outgoing', 'type1')"
                @refresh-nomination-states="updateCanNominateStates">
                <outgoing-cards surveyType="type1" :cards="surveys.outgoing.type1" />
              </survey-collapse-item-refresh>

              <survey-collapse-item-refresh 
                :title="typeNames.type2"
                :notifCount="getNotifCount(surveys.outgoing.type2)" :nominateButton="true"
                :nominateButtonDisabled="!canNominateState.type2"
                :nominationLimitReached="nominationLimitReached.type2"
                :typeNames="typeNames"
                @nominate="handleNominate"
                @refresh="refreshSurveys('outgoing', 'type2')"
                @refresh-nomination-states="updateCanNominateStates">
                <outgoing-cards surveyType="type2" :cards="surveys.outgoing.type2" />
              </survey-collapse-item-refresh>
            </app-collapse>
          </b-tab>

          <!-- Incoming Survey Requests TAB  -->
          <b-tab :title="`Incoming Requests ${getIncomingNotifCount(surveys) ? `(${getIncomingNotifCount(surveys)})`: ''}`">
            <app-collapse>
              <!-- <survey-collapse-item-refresh 
                :title="typeNames.type1" 
                :notifCount="getNotifCount(surveys.incoming.type1)"
                :typeNames="typeNames"
                @refresh="refreshSurveys('incoming', 'type1')">
                <incoming-cards surveyType="type1" :cards="surveys.incoming.type1" />
              </survey-collapse-item-refresh>
              <survey-collapse-item-refresh 
                :title="typeNames.type2"
                :notifCount="getNotifCount(surveys.incoming.type2)"
                :typeNames="typeNames"
                @refresh="refreshSurveys('incoming', 'type2')">
                <incoming-cards surveyType="type2" :cards="surveys.incoming.type2" />
              </survey-collapse-item-refresh> -->
              
              <incoming-cards :cards="surveys.incoming" />
            </app-collapse>
          </b-tab>

          <!-- Advisees Surveys TAB -->
          <b-tab v-if="hasAdvisees" :title="`Advisees Surveys ${getAdviseesNotifCount(surveys) ? `(${getAdviseesNotifCount(surveys)})`: ''}`">
            <app-collapse>
              <advisees-cards :cards="surveys.advisees" />
            </app-collapse>
          </b-tab>

          <!-- My Results TAB  -->
          <b-tab :title="resultsTabTitle" class="results-tab">
            <template #title>
              <span class="results-title d-flex align-items-center">
                <feather-icon icon="AwardIcon" class="mr-50 text-white"/>
                <span><strong>{{ resultsTabTitle }}</strong></span>
              </span>
            </template>

            <!-- Dropdown to select results -->
            <div class="d-flex justify-content-center">
              <b-dropdown v-if="hasAdvisees" variant="outline-primary" id="results-dropdown" :text="selectedResultText" class="mx-2">
                <b-dropdown-item @click="fetchAllResults()">My Results</b-dropdown-item>
                <b-dropdown-item v-for="advisee in advisees" :key="advisee._id" @click="fetchAdviseeResults(advisee._id)">
                  {{ advisee.fullName }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
             
            <!-- Overall Score / Advice -->
            <b-alert v-if="errorMessage" variant="danger" show class="text-center p-1 mt-2">
              {{ errorMessage }}
            </b-alert>
            <div v-else-if="resultsLoading" class="text-center p-2">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else class="text-center p-2">
              <h3 class="results-content-title" >Overall Score: <b-badge :variant="getBadgeVariant(scores.overall)">{{ scores.overall.toFixed(2) }}</b-badge></h3>
              <h5 class="" ><b>{{questionnaireTypes[category].type1}}:</b> <b-badge :variant="`light-${getBadgeVariant(scores.type1)}`">{{ scores.type1.toFixed(2) }}</b-badge> - <b>{{questionnaireTypes[category].type2}}:</b> <b-badge :variant="`light-${getBadgeVariant(scores.type2)}`">{{ scores.type2.toFixed(2) }}</b-badge></h5>
              <h5 class="mt-2 mb-0 pb-0">~ {{ overallAdvice }}</h5>
            </div>
            
            <!-- Comments by Competency -->
            <app-collapse v-if="!errorMessage && !resultsLoading">
              <!-- General Comments Section -->
              <b-card class="mb-1" header="General" header-tag="header" footer-tag="footer" border-variant="primary">
                <b-card-body>
                  <!-- General Comments -->
                  <comments-collapse-item-refresh 
                    v-if="generalComments['General Comment'] && generalComments['General Comment'].length" 
                    title="General Comment"
                    @refresh="handleCommentsRefresh">
                    <comments-cards :comments="generalComments['General Comment']" />
                  </comments-collapse-item-refresh>

                  <!-- Top Skills and Tips -->
                  <comments-collapse-item-refresh 
                    v-if="generalComments['Top Skills and Tips on strengthening them'] && generalComments['Top Skills and Tips on strengthening them'].length" 
                    title="Top Skills and Tips on strengthening them"
                    @refresh="handleCommentsRefresh">
                    <comments-cards :comments="generalComments['Top Skills and Tips on strengthening them']" />
                  </comments-collapse-item-refresh>

                  <!-- Top Detractors and Tips -->
                  <comments-collapse-item-refresh 
                    v-if="generalComments['Top Detractors and Tips on minimizing them'] && generalComments['Top Detractors and Tips on minimizing them'].length" 
                    title="Top Detractors and Tips on minimizing them"
                    @refresh="handleCommentsRefresh">
                    <comments-cards :comments="generalComments['Top Detractors and Tips on minimizing them']" />
                  </comments-collapse-item-refresh>
                </b-card-body>
              </b-card>

              <!-- Competency Comments -->
              <b-card class="" header="Competencies" header-tag="header" footer-tag="footer" border-variant="primary">
                <b-card-body>   
                  <comments-collapse-item-refresh 
                      v-for="(comments, competency) in allCompetencies" 
                      :key="competency"
                      :title="competency"
                      :competencyScores="getCompetencyScores(competency)"
                      @refresh="handleCommentsRefresh">
                      <comments-cards :comments="comments" />
                    </comments-collapse-item-refresh>
                  </b-card-body>
                </b-card>
            </app-collapse>
          </b-tab>
        </b-tabs>

        <!-- Nomination Modal -->
        <nominate-modal-vue ref="nominateModal" :surveyType="surveyType" :typeNames="typeNames"
          @nomination-success="handleNominationSuccess" />
      </div>
      <div v-else>
        <no-cycle-active />
      </div>
    </span>
  </span>
</template>

<script>
import { BTabs, BTab, BDropdown, BBadge, BDropdownItem, BAlert, BSpinner, BCard, BCardBody } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import SurveyCollapseItemRefresh from './components/collapse/SurveyCollapseItemRefresh.vue'
import CommentsCollapseItemRefresh from './components/collapse/CommentsCollapseItemRefresh.vue'
import OutgoingCards from './components/cards/OutgoingCards.vue'
import IncomingCards from './components/cards/IncomingCards.vue'
import AdviseesCards from './components/cards/AdviseesCards.vue'
import SelfEvalCard from './components/cards/SelfEvalCard.vue'
import ClientCards from './components/cards/ClientCards.vue'
import NominateModalVue from './components/modal/NominateModal.vue'
import NoCycleActive from './components/no-cycle-active/Card.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { getSurveyTypeFromTitle } from './utils'
import CommentsCards from './components/cards/CommentsCards.vue'

export default {
  components: {
    AppCollapse,
    SurveyCollapseItemRefresh,
    CommentsCollapseItemRefresh,
    NominateModalVue,
    IncomingCards,
    OutgoingCards,
    SelfEvalCard,
    AdviseesCards,
    ClientCards,
    NoCycleActive,
    BTabs,
    BTab,
    BBadge,
    BDropdown,
    BDropdownItem,
    CommentsCards,
    BAlert,
    BSpinner,
    BCard,
    BCardBody,
  },
  setup() {
    const { isNavMenuHidden } = useAppConfig()
    return {
      isNavMenuHidden
    }
  },
  beforeCreate() {
    this.$http.get('/cycles/isActive')
      .then(response => {
        this.isCycleActive = response.data.isActive
        this.loading = false
      })


    // Check if user has Advisees
    this.$http.get('/auth/me/hasAdvisees')
      .then(response => {
        this.hasAdvisees = response.data.hasAdvisees
      })
      .catch(error => console.log(error))

    this.$http.get('/surveys/allMySurveys')
      .then(response => {
        this.surveys = response.data
      })
      .catch(error => console.log(error))

    this.$http.get('/questionnaires/types')
      .then(response => {
        this.category = this.userData.level.category;
        this.questionnaireTypes = response.data;
        this.typeNames.type1 = response.data[this.category]?.type1 || "Type 1";
        this.typeNames.type2 = response.data[this.category]?.type2 || "Type 2";
      })
      .catch(error => console.log(error))

    this.$http.get('/results/myResults')
      .then(response => {
        
        this.scores = response.data.scores;
        this.overallAdvice = response.data.overallAdvice;
        this.competencyComments = response.data.competencies;
        this.generalComments = response.data.general;
      })
      .catch(error => console.log(error))
  },
  async created() {
    this.isNavMenuHidden = true
    await this.updateCanNominateStates()
    this.fetchAdvisees()
    this.fetchAllResults()
  },
  data() {
    return {
      loading: true,
      isCycleActive: false,
      surveyType: '',
      surveys: {
        selfEval: {},
        outgoing: {
          type1: [],
          type2: []
        },
        incoming: [],
      },
      canNominateState: {
        type1: false,
        type2: false,
      },
      nominationLimitReached: {
        type1: false,
        type2: false,
      },
      hasAdvisees: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      scores: {
        overall: 0,
        type1: 0,
        type2: 0,
        competency: {},
      }, 
      overallAdvice: '',
      comments: [
      ],
      questionnaireTypes: {},
      typeNames: {
        type1: '',
        type2: ''
      },
      competencyComments: {},
      generalComments: [],
      advisees: [],
      selectedResultText: 'My Results',
      category: '',
      selectedAdviseeId: null,
      errorMessage: '',
      resultsLoading: false,
    }
  },
  computed: {
    resultsTabTitle() {
      return this.selectedResultText === 'My Results' ? 'My Results' : `${this.selectedResultText}'s Results`
    },
    allCompetencies() {
      const competencies = { ...this.competencyComments };
      return competencies;
    }
  },
  methods: {
    getSurveyTypeFromTitle,
    getNotifCount(surveys, isAdvisees = false) {
      if (isAdvisees) {
        return surveys?.filter(obj => obj.status === 'In review').length || 0;
      }
      return surveys?.filter(obj => obj.isSubmitted === false).length || 0;
    },
    getIncomingNotifCount(allSurveys) {
      return this.getNotifCount(allSurveys.incoming)
    },
    getAdviseesNotifCount(allSurveys) {

      if (this.hasAdvisees) {
        return this.getNotifCount(allSurveys.advisees, true)
      }
      return 0
    },
    getCompetencyScores(competency) {
      
      const type1Name = this.questionnaireTypes[this.category].type1
      const type2Name = this.questionnaireTypes[this.category].type2
      const type1Score = this.scores.competency[competency].type1
      const type2Score = this.scores.competency[competency].type2

      // return `(${type1Name}: ${type1Score.toFixed(2)} - ${type2Name}: ${type2Score.toFixed(2)})`
      const res = {}
      res[type1Name] = type1Score
      res[type2Name] = type2Score
      return res
    },
    async canNominate(type) {
      const res = await this.$http.get(`/users/canNominate?type=${type}`)
      return res.data
    },
    async updateCanNominateStates() {
      const type1Data = await this.canNominate('type1')
      const type2Data = await this.canNominate('type2')
      this.canNominateState.type1 = type1Data.canNominate
      this.canNominateState.type2 = type2Data.canNominate

      this.nominationLimitReached.type1 = type1Data.nominationLimitReached || false;
      this.nominationLimitReached.type2 = type2Data.nominationLimitReached || false;
    },
    handleNominate(title) {
      this.surveyType = this.getSurveyTypeFromTitle(title, this.typeNames)
      this.$refs.nominateModal.show()
    },
    handleNominationSuccess(surveyType) {
      this.refreshSurveys('outgoing', surveyType);
      this.updateCanNominateStates(); // Refresh nomination states
    },
    refreshSurveys(category, type) {
      this.$http.get(`/surveys/${category}?type=${type}`)
        .then(response => {
          this.surveys[`${category}`][`${type}`] = response.data
        })
        .catch(error => console.error(error));
    },
    getBadgeVariant(score) {
      if (score > 2) return 'success';
      if (score > 1) return 'warning';
      if (score >= 0) return 'danger';
      return 'primary';
    },
    fetchAllResults() {
      this.selectedAdviseeId = null
      this.selectedResultText = 'My Results'
      this.errorMessage = ''
      this.resultsLoading = true
      this.$http.get('/results/myResults')
        .then(response => {
          this.scores = response.data.scores;
          this.overallAdvice = response.data.overallAdvice;
          this.competencyComments = response.data.competencies;
          this.generalComments = response.data.general || {
            "General Comment": [],
            "Top Skills and Tips on strengthening them": [],
            "Top Detractors and Tips on minimizing them": []
          };
          this.resultsLoading = false;
          this.category = response.data.category;
          this.$emit('results-fetched');
        })
        .catch(error => {
          this.resultsLoading = false
          if (error.response && error.response.status === 400) {
            this.errorMessage = (error.response && error.response.data && error.response.data.error) || error || 'No results to display.'
          } else {
            console.log(error)
          }
        })
    },
    fetchAdvisees() {
      this.$http.get('/users/myAdvisees')
        .then(response => {
          this.advisees = response.data
        })
        .catch(error => console.log(error))
    },
    fetchAdviseeResults(adviseeId) {
      this.selectedAdviseeId = adviseeId
      const advisee = this.advisees.find(a => a._id === adviseeId)
      this.selectedResultText = advisee ? advisee.fullName : 'My Results'
      this.errorMessage = ''
      this.resultsLoading = true

      this.$http.get(`/results/myResults/${adviseeId}`)
        .then(response => {
          this.scores = response.data.scores;
          this.overallAdvice = response.data.overallAdvice;
          this.competencyComments = response.data.competencies;
          this.generalComments = response.data.general || {
            "General Comment": [],
            "Top Skills and Tips on strengthening them": [],
            "Top Detractors and Tips on minimizing them": []
          };
          this.category = response.data.category;
          this.resultsLoading = false
          this.$emit('results-fetched')
        })
        .catch(error => {
          this.resultsLoading = false
          if (error.response && error.response.status === 400) {
            this.errorMessage = (error.response && error.response.data && error.response.data.error) || error || 'No results to display.'
          } else {
            console.log(error)
          }
        })
    },
    handleCommentsRefresh() {
      if (this.selectedAdviseeId) {
        this.fetchAdviseeResults(this.selectedAdviseeId)
      } else {
        this.fetchAllResults()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.results-tab {
  .nav-link {
    background-color: $primary !important;
    color: #fff !important;
    border-radius: 0.25rem !important;
    font-weight: bold !important;
  }
  .nav-link.active {
    background-color: darken($primary, 10%) !important;
    color: #fff !important;
    border-bottom: 3px solid darken($primary, 10%) !important;
  }
}

.results-title {
  background-color: $primary;
  color: #fff;
  width: 100%;
  padding: 0.15rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.results-content-title {
  font-size: 1.5rem;
  color: #007bff;
}

</style>
