<template>
  <b-card-actions-comments class="mb-50" ref="refreshCard" :title="title" :competencyScores="competencyScores" action-refresh action-collapse @refresh="refresh('refreshCard')">
    <b-card-body>
      <slot />
    </b-card-body>
  </b-card-actions-comments>
</template>

<script>
import BCardActionsComments from './b-card-actions/BCardActionsComments.vue'
import { BCard, BCardBody } from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardActionsComments,
    BCardBody,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    competencyScores: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      collapseItemID: '',
    }
  },
  created() {
    this.collapseItemID = uuidv4()
  },
  methods: {
    refresh(cardName) {
      this.$emit('refresh')
    },
  },
}
</script>
