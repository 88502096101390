<template>
  <b-list-group>
    <b-list-group-item v-for="(comment, index) in comments" :key="index">{{ comment }}</b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
}
</script>
