<template>
  <b-card-actions class="mb-50" ref="refreshCard" :title="title" :notifCount="notifCount"
    :nominateButton="nominateButton" :nominateButtonDisabled="nominateButtonDisabled"
    :nominationLimitReached="nominationLimitReached"
    action-refresh action-collapse @refresh="refresh('refreshCard')"
    @nominate="emitNominate">
    <b-card-body>
      <slot />
    </b-card-body>
  </b-card-actions>
</template>

<script>
import BCardActions from './b-card-actions/BCardActions.vue'
import {
  BCard, BCardHeader, BCardBody, BCollapse, BBadge
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { getSurveyTypeFromTitle } from '../../utils'

export default {
  components: {
    BCard,
    BCardActions,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
  },
  props: {
    nominateButton: {
      type: Boolean,
      required: false,
    },
    nominateButtonDisabled: {
      type: Boolean,
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    notifCount: {
      type: Number,
      required: false,
      default: 0,
    },
    typeNames: {
      type: Object,
      required: true,
    },
    nominationLimitReached: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    getSurveyTypeFromTitle,
    // stop refreshing card in 1 sec
    refresh(cardName) {
      this.$emit('refresh', this.getSurveyTypeFromTitle(this.title, this.typeNames));
      // setTimeout(() => {
      //   this.$refs[cardName].showLoading = false
      // }, 1000)
    },
    emitNominate() {
      this.$emit('nominate', this.title);
      this.$emit('refresh-nomination-states'); // Emit event to refresh nomination states
    },
    updateVisible(val = true) {
      this.visible = val
      if (this.visible) {
        this.$emit('update:notifCount', 0); // Emit event to reset notifCount if needed
      }
      this.$refs.refreshCard.triggerCollapse();
    },
    collapseOpen() {
      if (this.openOnHover) {
        this.updateVisible(true);
      }
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>
